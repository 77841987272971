<template>
  <span>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

    <div class="content">
      <view-data v-if="viewType == 'VIEW'" @changeViewType="changeViewType(...arguments)" :has-add-perawat="hasAddPerawat" :has-fill-criteria="hasFillCriteria" />
      <form-data v-else />
    </div>
  </span>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import ViewData from './ViewData.vue'
import FormData from './FormData.vue'
import GlobalVue from '@/libs/Global.vue'

import Gen from '@/libs/Gen.js'

const _ = global._

export default {
  extends: GlobalVue,
  components: {
    PatientInfo, ViewData, FormData
  },
  data(){
    return {
      patientData: {},
      loading: {
        patientInfo: false,
        coreData: false
      },

      hasAddPerawat: false,
      hasFillCriteria: false,
      listDischargePlanning: [],

      mrPendidikanKesehatan: [],
      mrHasilPemeriksaan: [],
      mrDokter: [],
      mrPlace: [],
      mrValidation: {},

      viewType: "VIEW"
    }
  },

  mounted(){    
    if(this.$route.query.viewType){
      this.changeViewType(this.$route.query.viewType)
    }

    this.getPatientInfo()

    if(this.viewType == 'VIEW'){
      this.getList()
    } else {
      this.getForm()
    }
  },

  methods: {
    downloadDischargePlanning(data){
      this.$swal.fire({
        title: 'Processing...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/RanapDPPerawat", {
          data: {
            type: "download-discharge-planning",
            id_registrasi: this.$route.params.pageSlug,
            ...data
          }
        },
        "POST"
      )
        .then(res => {
          const linkSource = res.data
          const downloadLink = document.createElement("a")
          downloadLink.href = linkSource
          downloadLink.download = `Discharge-Planning-${new Date().getTime()}.pdf`
          downloadLink.click()

          this.$swal.close()
          this.$bvModal.hide("previewDownload")
        })
        .catch(()=>{
          this.$swal.close()
          // this.globalVar.apiGenError( err.response.data )
        })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getList(){
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/RanapDPPerawat", {
          params: {
            type: "get-list",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this, 'hasAddPerawat', res.data.hasAddPerawat)
        this.$set(this, 'hasFillCriteria', res.data.hasFillCriteria)
        this.$set(this.loading, 'coreData', false)
      })
    },

    getForm(){
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/RanapDPPerawat", {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })
    },

    changeViewType(view){
      this.$set(this, 'viewType', view)
    },

    doSubmit(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/RanapDPPerawat", {
          data: {
            ...data,
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: this.modulePage,
              params: { ...this.$route.params }
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    }
  },  

  computed: {},
  watch: {
    $route(v) {
      this.getPatientInfo()
      if(this.viewType == 'VIEW' || !v.query.viewType){
        this.getList()
      } else {
        this.getForm()
      }

      if(!v.query.viewType || v.query.viewType == 'VIEW')
        this.changeViewType('VIEW')
    },
    viewType(v){
      this.$router.push({ 
        name: this.modulePage, 
        query: { viewType: v }, 
        params: { ...this.$route.params } 
      })
    }
  },
}
</script>