<template>
<div>
  <div class="page-title pt-0 pb-3">
    <a href="javascript:;" @click="$router.push({ name: 'RanapPasienPerawatan' })" class="text-dark">
      <i class="icon-arrow-left52 mr-1"></i>
      <span class="font-weight-semibold">Kembali</span>
    </a>
  </div>
  <div class="row g-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <h5 class="card-title font-weight-semibold mr-auto">Discharge Planning</h5>
            <div v-if="hasFillCriteria">
              <button type="button" @click="openPreviewDownload()" class="btn bg-success-600 btn-labeled btn-labeled-left mr-2">
                <b><i class="icon-download"></i></b>Download
              </button>
              <a href="javascript:;" @click="$emit('changeViewType', 'FORM_DISCHARGE_PLANNING')" class="btn bg-info-600 btn-labeled btn-labeled-left" v-if="$parent.moduleRole('edit') && !hasAddPerawat">
                <b><i class="icon-plus2"></i></b>Tambah Discharge Planning
              </a>
            </div>
          </div>
        </div>
        <table class="table table-bordered table-sm patient-table">
          <thead>
            <tr>
              <th width="25">No.</th>
              <th>PPA </th>
              <th>Nama </th>
              <th>Tanggal/Jam</th>
              <th>Status</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, k) in listDischargePlanning" :key="`ldp-${k}`">
              <td><span>{{ k + 1}}</span></td>
              <template v-if="!Object.keys(v.data).length">
                <td colspan="10">
                  <span class="font-weigght-semibold text-uppercase">
                    Belum Ada Discharge Planning pada PPA ({{ v.ppa }})
                  </span>
                </td>
              </template>
              <template v-else>
                <td><span class="font-weight-semibold text-capitalize">{{ v.ppa }}</span> </td>
                <td>{{ v.data.nama_petugas || '-' }} </td>
                <td>{{ (v.data.updated_date || v.data.created_date) | moment("LLL") }}</td>

                <td>
                  <span class="badge bg-warning mb-1" v-if="v.data.status === 'MENUNGGU'">Menunggu </span>
                  <span class="badge bg-teal mb-1" v-else>Selesai </span>
                </td>
                <td>
                  <a href="javascript:;" @click="openDetail(v)" data-toggle="modal" class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" data-popup="tooltip" title="" data-original-title="Lihat Detail"><i class="icon-file-eye"></i></a>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal id="perawatDetailData" size="lg" hide-footer title="Discharge Planning Perawat">
      <div class="mb-3">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                  <h4>Tanggal/Jam</h4>
                  <p>{{ (dataModal.updated_date || dataModal.created_date) | moment("LLL") }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>PPA</h4>
                  <p class="text-capitalize">{{ dataModal.ppa }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{ dataModal.nama_petugas }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Status</h4>

                  <span class="badge bg-warning mb-1" v-if="dataModal.status === 'MENUNGGU'">Menunggu </span>
                  <span class="badge bg-teal mb-1" v-else>Selesai </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <template v-if="dataModal.status == 'SELESAI'">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-12" v-for="(v, k) in dataModal.pendidikan_kesehatan" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle">
                          {{ v.label }} {{ v.note ? `: ${v.note}` : `` }} <span class="text-capitalize" v-if="v.suffix">{{ v.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Hasil Pemeriksaan dan Surat yang Dibawakan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-12" v-for="(v, k) in dataModal.hasil_pemeriksaan" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle">
                          {{ v.label }} {{ v.note ? `: ${v.note}` : `` }} <span class="text-capitalize" v-if="v.suffix">{{ v.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Medis Sebelum Kontrol</h6>
              </div>
              <div class="card-body p-3">
                <p class="mb-0">{{ dataModal.pemeriksaan_medis }}</p>
              </div>
            </div>

            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Jadwal Kontrol Berikutnya</h6>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Nama Dokter</th>
                      <th>Tanggal</th>
                      <th>Waktu Periksa</th>
                      <th>Tempat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in dataModal.jadwal_kontrol" :key="`jk-${k}`">
                      <td>{{ v.nama_dokter }}</td>
                      <td>{{ v.date | moment("LL") }}</td>
                      <td>{{ v.waktu_periksa || "-"}}</td>
                      <td>{{ v.place }}</td>
                    </tr>

                    <tr v-if="!(dataModal.jadwal_kontrol||[]).length">
                      <td colspan="3">
                        <p class="mb-0 text-center">Tidak ada data jadwal kontrol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Tanda Tangan Perawat/Bidan, <br>
              ({{ dataModal.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataModal.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Silakan atur discharge planning terlebih dahulu
          </b-alert>
        </div>
      </div>
    </b-modal>

    <b-modal id="fisioterapisDetailData" size="md" hide-footer title="Discharge Planning Aktivitas">
      <div class="mb-3">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                  <h4>Tanggal/Jam</h4>
                  <p>{{ (dataModal.updated_date || dataModal.created_date) | moment("LLL") }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>PPA</h4>
                  <p class="text-capitalize">{{ dataModal.ppa }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{ dataModal.nama_petugas }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Status</h4>

                  <span class="badge bg-warning mb-1" v-if="dataModal.status === 'MENUNGGU'">Menunggu </span>
                  <span class="badge bg-teal mb-1" v-else>Selesai </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <template v-if="dataModal.status == 'SELESAI'">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-6" v-for="(v, k) in pendidikanKesehatanGroups" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2" v-for="(dt, kdt) in v" :key="`dt-${kdt}`">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle">
                          {{ dt.label }} {{ dt.note ? `: ${dt.note}` : `` }} <span class="text-capitalize" v-if="dt.suffix">{{ dt.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataModal.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataModal.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Silakan atur discharge planning terlebih dahulu
          </b-alert>
        </div>
      </div>
    </b-modal>

    <b-modal id="farmasiDetailData" size="lg" hide-footer title="Discharge Planning Penggunaan Obat">
      <div class="mb-3">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                  <h4>Tanggal/Jam</h4>
                  <p>{{ (dataModal.updated_date || dataModal.created_date) | moment("LLL") }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>PPA</h4>
                  <p class="text-capitalize">{{ dataModal.ppa }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{ dataModal.nama_petugas }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Status</h4>

                  <span class="badge bg-warning mb-1" v-if="dataModal.status === 'MENUNGGU'">Menunggu </span>
                  <span class="badge bg-teal mb-1" v-else>Selesai </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <template v-if="dataModal.status == 'SELESAI'">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="160">Jenis</th>
                    <th width="320">Nama</th>
                    <th width="150">Jumlah</th>
                    <th width="210">Frekuensi</th>
                    <th>Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Racikan</td>
                    <td>
                      <a href="javascript:;" data-popup="tooltip" title="Lihat Detail Resep" data-toggle="modal" data-target="#modalRacikanDetail" class="font-weight-semibold border-bottom">Racikan
                        Amlodipin + Amoxicilin</a>
                    </td>
                    <td>2 Botol</td>
                    <td>3 x 1</td>
                    <td>Dihabiskan</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataModal.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataModal.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Silakan atur discharge planning terlebih dahulu
          </b-alert>
        </div>
      </div>
    </b-modal>

    <b-modal id="dietisienDetailData" size="md" hide-footer title="Discharge Planning Dietisien">
      <div class="mb-3">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                  <h4>Tanggal/Jam</h4>
                  <p>{{ (dataModal.updated_date || dataModal.created_date) | moment("LLL") }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>PPA</h4>
                  <p class="text-capitalize">{{ dataModal.ppa }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{ dataModal.nama_petugas }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Status</h4>

                  <span class="badge bg-warning mb-1" v-if="dataModal.status === 'MENUNGGU'">Menunggu </span>
                  <span class="badge bg-teal mb-1" v-else>Selesai </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <template v-if="dataModal.status == 'SELESAI'">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-6" v-for="(v, k) in pendidikanKesehatanGroups" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2" v-for="(dt, kdt) in v" :key="`dt-${kdt}`">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle">
                          {{ dt.label }} {{ dt.note ? `: ${dt.note}` : `` }} <span class="text-capitalize" v-if="dt.suffix">{{ dt.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataModal.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataModal.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Silakan atur discharge planning terlebih dahulu
          </b-alert>
        </div>
      </div>
    </b-modal>

    <b-modal id="previewDownload" size="xl" hide-footer title="Preview">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Nama &amp; Tanda Tangan Pasien/Keluarga <span class="text-danger">*</span></label>
            <b-form-input placeholder="Nama Perawat/Bidan" v-model="dataModal.nama_pasien" @change="setSignatureName($event, 'namaPasien')" />
            <VValidate name="Nama Pasien/Keluarga" v-model="dataModal.nama_pasien" rules="required" />

            <div class="signing-element mt-1" data-target-id="ttdPasien">
              <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdPasien" @onend="onEnd('ttdPasien')" :options="{onBegin: () => {$refs.ttdPasien.resizeCanvas()}}" />

              <VValidate name="Tanda Tangan Pasien/Keluarga" v-model="dataModal.signature_pasien" rules="required" />

              <div class="mt-2">
                <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" @click="undoTTD('ttdPasien','signature_pasien')">
                  <b><i class="icon-cross3"></i></b>
                  <span>Clear</span>
                </button>
                <button type="button" @click="output('ttdPasien','signature_pasien')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                  <b><i class="icon-checkmark2"></i></b>
                  <span>Simpan</span>
                </button>
              </div>
            </div>

          </div>

          <div class="form-group">
            <label for="">Nama &amp; Tanda Tangan Perawat <span class="text-danger">*</span></label>
            <b-form-input placeholder="Nama Perawat" v-model="dataModal.nama_perawat" @change="setSignatureName($event, 'namaPerawat')" />
            <VValidate name="Nama Perawat" v-model="dataModal.nama_perawat" rules="required" />

            <div class="signing-element mt-1" data-target-id="ttdPerawat">
              <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdPerawat" @onend="onEnd('ttdPerawat')" :options="{onBegin: () => {$refs.ttdPerawat.resizeCanvas()}}" />
              <VValidate name="Tanda Tangan Perawat" v-model="dataModal.signature_perawat" rules="required" />

              <div class="mt-2">
                <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" @click="undoTTD('ttdPerawat','signature_perawat')">
                  <b><i class="icon-cross3"></i></b>
                  <span>Clear</span>
                </button>
                <button type="button" @click="output('ttdPerawat','signature_perawat')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                  <b><i class="icon-checkmark2"></i></b>
                  <span>Simpan</span>
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-9">
          <b-overlay :show="dataModal.loadingTemplate">
            <span v-html="dataModal.template"></span>
          </b-overlay>
        </div>

        <div class="col-md-12 mt-3">
          <div class="text-right">
            <button type="button" @click="$parent.downloadDischargePlanning({...dataModal, action: 'download'})" class="btn bg-success-600 btn-labeled btn-labeled-left">
              <b><i class="icon-download"></i></b>Download
            </button>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
const _ = global._

export default {
  props: {
    hasAddPerawat: Boolean,
    hasFillCriteria: Boolean,
  },
  components: {},
  data() {
    return {
      dataModal: {}
    }
  },

  methods: {
    setSignatureName(e, id){
      const el = document.querySelector(`#${id}`)
      if(el) el.textContent = e
    },
    openPreviewDownload(){
      this.$bvModal.show('previewDownload')
      this.$set(this.dataModal, 'loadingTemplate', true)

      Gen.apiRest(
        "/do/RanapDPPerawat", {
          data: {
            type: "download-discharge-planning",
            id_registrasi: this.$route.params.pageSlug,
            action: 'preview'
          }
        },
        "POST"
      ).then(res => {
        this.$set(this, 'dataModal', {
          loadingTemplate: false,
          template: res.data
        })
      }).catch(() => {
        this.$set(this.dataModal, 'loadingTemplate', false)
      })
    },
    openDetail(data) {
      if (data.ppa == "perawat") {
        Gen.apiRest(
          "/get/" + 'RanapDPPerawat', {
            params: {
              id: data.data.id,
              type: 'get-jadwal-kontrol'
            }
          },
        ).then(res => {
          this.$set(this, 'dataModal', {
            ...data.data,
            jadwal_kontrol: res.data.data,
            ppa: data.ppa
          })
        })
      } else {
        this.$set(this, 'dataModal', {
          ...data.data,
          ppa: data.ppa
        })
      }
      this.$bvModal.show(`${data.ppa}DetailData`)
    },

    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.dataModal[field] = null

      const elImgSignature = document.querySelector(`#${field}`)
      if(elImgSignature) elImgSignature.setAttribute('src', '')
    },
    output(refs, field) {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this.dataModal[field] = data

        const elImgSignature = document.querySelector(`#${field}`)
        if(elImgSignature) elImgSignature.setAttribute('src', data)
      }
    },
  },

  computed: {
    listDischargePlanning() {
      return this.$parent.listDischargePlanning
    },

    pendidikanKesehatanGroups() {
      return _.chunk(this.dataModal.pendidikan_kesehatan, 2)
    }
  }
}
</script>
