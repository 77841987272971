<template>
<div class="card">
  <div class="card-header bg-white">
    <h6 class="card-title font-weight-semibold">Form Discharge Planning Perawatan </h6>
  </div>
  <validation-observer ref="VFormDPPerawat">
    <b-form @submit.prevent="doSubmit()">
      <div class="card-body">
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Perawatan lanjutan di rumah</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-4" v-for="(v, k) in mrPendidikanKesehatanGroups" :key="`pk-${k}`">
                <div>
                  <div class="mb-2" v-for="(dt, kdt) in v" :key="`dt-${k}-${kdt}`">
                    <b-form-checkbox :id="`mpkc-${k}-${kdt}`" v-model="row.pendidikan_kesehatan" :name="`mpkc-${k}-${kdt}`" :key="`mpkc-${k}-${kdt}`" :value="{ ...dt }">
                      {{ dt.label }}

                      <div class="input-group mt-2" v-if="dt.has_note && checkDataRow(dt.value, 'pendidikan_kesehatan')">
                        <b-form-input class="form-control" :placeholder="dt.placeholder" @change="updateValueCheckbox($event, dt.value, 'pendidikan_kesehatan', row.pendidikan_kesehatan)" :value="dt.note" :formatter="dt.formatter ? Formatter[dt.formatter] : Formatter.default" />
                        <div class="input-group-append" v-if="dt.suffix">
                          <span class="input-group-text">{{ dt.suffix }}</span>
                        </div>
                      </div>
                    </b-form-checkbox>

                  </div>
                </div>
              </div>
            </div>

            <VValidate name="Pendidikan Kesehatan yang Diberikan" :value="(row.pendidikan_kesehatan||[]).length > 0 ? true : null" :rules="mrValidation.pendidikan_kesehatan" />
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Hasil pemeriksaan dan surat yang dibawakan </h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-4" v-for="(v, k) in mrHasilPemeriksaanGroups" :key="`hpk-${k}`">
                <div>
                  <div class="mb-2" v-for="(dt, kdt) in v" :key="`dt-${k}-${kdt}`">
                    <b-form-checkbox :id="`mhpc-${k}-${kdt}`" v-model="row.hasil_pemeriksaan" :name="`mhpc-${k}-${kdt}`" :key="`mhpc-${k}-${kdt}`" :value="{ ...dt }">
                      {{ dt.label }}

                      <div class="input-group mt-2" v-if="dt.has_note && checkDataRow(dt.value, 'hasil_pemeriksaan')">
                        <b-form-input class="form-control" :placeholder="dt.placeholder" @change="updateValueCheckbox($event, dt.value, 'hasil_pemeriksaan', row.hasil_pemeriksaan)" :value="dt.note" :formatter="dt.formatter ? Formatter[dt.formatter] : Formatter.default" />
                        <div class="input-group-append" v-if="dt.suffix">
                          <span class="input-group-text">{{ dt.suffix }}</span>
                        </div>
                      </div>
                    </b-form-checkbox>

                  </div>
                </div>
              </div>
            </div>

            <VValidate name="Hasil Pemeriksaan" :value="(row.hasil_pemeriksaan||[]).length > 0 ? true : null" :rules="mrValidation.hasil_pemeriksaan" />
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pemeriksaan medis sebelum kontrol </h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <b-form-textarea rows="6" placeholder="" v-model="row.pemeriksaan_medis"></b-form-textarea>

                <VValidate name="Pemeriskaan medis" v-model="row.pemeriksaan_medis" :rules="mrValidation.pemeriksaan_medis" />
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Transportasi pulang </h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <b-form-textarea rows="6" placeholder="" v-model="row.transportasi_pulang"></b-form-textarea>

                <VValidate name="Transportasi pulang" v-model="row.transportasi_pulang" :rules="mrValidation.transportasi_pulang" />
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Jadwal Kontrol Berikutnya </h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <div>
                
                  <table class="table table-bordered table-striped table-hover table-sm patient-table">
                    <thead>
                      <tr>
                        <th width="250">Nama dokter</th>
                        <th width="250">Tanggal </th>
                        <th width="250">Waktu Periksa </th>
                        <th width="250">Tempat </th>
                        <th width="10">Aksi </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in row.jadwal_kontrol" :key="`jk-${k}`">
                        <td>
                          <v-select 
                            v-model="v.dokter"
                            :options="$parent.mrDokter"
                            :reduce="v => v.value"
                            label="text"
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.date">
                            </datepicker>
                          </div>
                        </td>
                        <td>
                          <v-select 
                            v-model="v.waktu_periksa"
                            :options="$parent.Config.mr.mWaktuPeriksa"
                            :reduce="v => v.value"
                            label="text"
                          />
                        </td>
                        <td>
                          <v-select 
                            v-model="v.place"
                            :options="$parent.mrPlace"
                            :reduce="v => v.value"
                          />
                        </td>
                        <td>
                          <a href="javascript:;" @click="deleteJadwalKontrol(v, k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800 mb-1" data-popup="tooltip" title="Hapus Jadwal" v-if="(row.jadwal_kontrol||[]).length > 1">
                            <i class="icon-trash"></i>
                          </a>

                          <a href="javascript:;" v-if="v.dokter && v.date && v.waktu_periksa && v.place == 'RSH'" @click="viewJadwalKontrol(v, k)" class="btn btn-icon btn-sm rounded-round alpha-info border-info text-info-800" data-popup="tooltip" title="Lihat Jadwal Dokter" >
                            <i class="icon-eye"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="5" class="text-center">
                          <a href="javascript:;" @click="addData('jadwal_kontrol')" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Nama &amp; Tanda Tangan Perawat/Bidan <span class="text-danger">*</span></label>
              <b-form-input placeholder="Nama Perawat/Bidan" v-model="row.nama_petugas" />
              <VValidate name="Nama" v-model="row.nama_petugas" :rules="mrValidation.nama_petugas" />

              <div class="signing-element mt-1" data-target-id="signature">
                <VueSignaturePad class="sign-container" width="100%" height="160px" ref="signature" @onend="onEnd('signature')" />

                <VValidate name="Tanda Tangan" v-model="row.signature" :rules="mrValidation.signature" />

                <div class="mt-2">
                  <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" @click="undoTTD('signature','signature')">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>
                  <button type="button" @click="output('signature','signature')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                    <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" class="btn" @click="$router.back()">Kembali</button>
          <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </b-form>
  </validation-observer>

  <b-modal :title="'Jadwal Dokter'" size="md" ok-title="Tutup" ok-only v-model="openJadwal">
    <div class="row sc-row g-0">
      <div class="col-md-4 col-lg-4 p-1">
        <div class="card shadow-0 border" style="min-height: 150px;">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title text-uppercase font-weight-semibold">Senin</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div v-for="(v,k) in (jadwalKontrolData.mon||[])" :key="k">
                <i class="icon-checkmark-circle text-success align-middle mr-1"></i><span
                  class="align-middle font-weight-semibold">{{v.start}} sd. {{v.end}}</span>
              </div>
              <span class="text-center" v-if="!(jadwalKontrolData.mon||[]).length">
                Tidak ada jadwal
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-4 p-1">
        <div class="card shadow-0 border" style="min-height: 150px;">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title text-uppercase font-weight-semibold">Selasa</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div v-for="(v,k) in (jadwalKontrolData.tue||[])" :key="k">
                <i class="icon-checkmark-circle text-success align-middle mr-1"></i><span
                  class="align-middle font-weight-semibold">{{v.start}} sd. {{v.end}}</span>
              </div>
              <span class="text-center" v-if="!(jadwalKontrolData.tue||[]).length">
                Tidak ada jadwal
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-4 col-lg-4 p-1">
        <div class="card shadow-0 border" style="min-height: 150px;">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title text-uppercase font-weight-semibold">Rabu</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div v-for="(v,k) in (jadwalKontrolData.wed||[])" :key="k">
                <i class="icon-checkmark-circle text-success align-middle mr-1"></i><span
                  class="align-middle font-weight-semibold">{{v.start}} sd. {{v.end}}</span>
              </div>
              <span class="text-center" v-if="!(jadwalKontrolData.wed||[]).length">
                Tidak ada jadwal
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-4 col-lg-4 p-1">
        <div class="card shadow-0 border" style="min-height: 150px;">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title text-uppercase font-weight-semibold">Kamis</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div v-for="(v,k) in (jadwalKontrolData.thu||[])" :key="k">
                <i class="icon-checkmark-circle text-success align-middle mr-1"></i><span
                  class="align-middle font-weight-semibold">{{v.start}} sd. {{v.end}}</span>
              </div>
              <span class="text-center" v-if="!(jadwalKontrolData.thu||[]).length">
                Tidak ada jadwal
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-4 col-lg-4 p-1">
        <div class="card shadow-0 border" style="min-height: 150px;">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title text-uppercase font-weight-semibold">Jumat</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div v-for="(v,k) in (jadwalKontrolData.fri||[])" :key="k">
                <i class="icon-checkmark-circle text-success align-middle mr-1"></i><span
                  class="align-middle font-weight-semibold">{{v.start}} sd. {{v.end}}</span>
              </div>
              <span class="text-center" v-if="!(jadwalKontrolData.fri||[]).length">
                Tidak ada jadwal
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-4 col-lg-4 p-1">
        <div class="card shadow-0 border" style="min-height: 150px;">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title text-uppercase font-weight-semibold">Sabtu</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div v-for="(v,k) in (jadwalKontrolData.sat||[])" :key="k">
                <i class="icon-checkmark-circle text-success align-middle mr-1"></i><span
                  class="align-middle font-weight-semibold">{{v.start}} sd. {{v.end}}</span>
              </div>
              <span class="text-center" v-if="!(jadwalKontrolData.sat||[]).length">
                Tidak ada jadwal
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </b-modal>  
</div>
</template>

<script>
import Formatter from '@/libs/Formatter.js'
import Gen from '@/libs/Gen'
import Datepicker from 'vuejs-datepicker'
const _ = global._

export default {
  props: {},
  components: {
    Datepicker
  },
  data() {
    return {
      formData: {
        pendidikan_kesehatan: [],
        hasil_pemeriksaan: [],
      },
      jadwalKontrolData: [],
      openJadwal: false
    }
  },

  mounted() {
    this.setSignature(this.row.signature)
  },

  methods: {
    viewJadwalKontrol(data, key){
      this.$parent.loadingOverlay = true
      Gen.apiRest(
        "/do/" + 'RanapDPPerawat', {
          data: {
            date: data.date,
            dokter: data.dokter,
            waktu_periksa: data.place,
            type: 'view-jadwal-kontrol'
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.jadwalKontrolData = resp.data
        this.openJadwal = true
        this.$parent.loadingOverlay = false
      })
    },
    deleteJadwalKontrol(data, key){
      if(data.id){
        Gen.apiRest(
          "/do/" + 'RanapDPPerawat', {
            data: {
              id: data.id,
              type: 'delete-jadwal-kontrol'
            }
          },
          "POST"
        ).then(() => {
          this.row.jadwal_kontrol.splice(key,1)
        })
      } else {
        this.row.jadwal_kontrol.splice(key,1)
      }
    },
    addData(property) {
      if (!this.row[property]) {
        this.$set(this.row, property, [{}])
      } else {
        this.row[property].push({})
      }
    },
    doSubmit() {
      this.$refs['VFormDPPerawat'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = { ...this.row, ...this.formData }
            this.$parent.doSubmit({
              ...payload,
              id_registrasi: this.$route.params.pageSlug,
              type: 'update-discharge-planning'
            })
          }
        })
      })
    },

    updateValueCheckbox(value, keyValue, keyData, data) {
      this.$set(this.formData, keyData, data.map(dt => {
        if (dt.value === keyValue) return {
          ...dt,
          note: value
        }

        return {
          ...dt
        }
      }))
    },

    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs, field) {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this.row[field] = data
      }
    },

    checkDataRow(value, keyData) {
      const indexKeyData = this.formData[keyData].findIndex(dt => dt.value === value)
      return indexKeyData >= 0
    },

    setSignature(signature) {
      setTimeout(() => {
        if (signature) this.$refs['signature'].fromDataURL(signature)
      }, 1000)
    },

  },

  computed: {
    mrPendidikanKesehatanGroups() {
      return _.chunk(this.$parent.mrPendidikanKesehatan, 6)
    },

    mrHasilPemeriksaanGroups() {
      return _.chunk(this.$parent.mrHasilPemeriksaan, 6)
    },

    mrValidation() {
      return this.$parent.mrValidation
    },
    row() {
      return this.$parent.row
    },

    Formatter() {
      return Formatter
    }
  },

  watch: {
    'row.pendidikan_kesehatan'(v) {
      const data = JSON.parse(JSON.stringify([...v]))
      this.$set(this.formData, 'pendidikan_kesehatan', data)
    },
    'row.hasil_pemeriksaan'(v) {
      const data = JSON.parse(JSON.stringify([...v]))
      this.$set(this.formData, 'hasil_pemeriksaan', data)
    },
    'row.signature'(v) {
      this.setSignature(v)
    }
  }
}
</script>
